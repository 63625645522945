import { isObject } from "@vue/shared";
import { cloneDeep } from "lodash";
import config from "@/config";
import { ElMessageBox } from "element-plus";
/**
 * @description 添加单位
 * @param {String | Number} value 值 100
 * @param {String} unit 单位 px em rem
 */
export const addUnit = (value: string | number, unit = "px") => {
  return !Object.is(Number(value), NaN) ? `${value}${unit}` : value;
};

/**
 * @description 添加单位
 * @param {unknown} value
 * @return {Boolean}
 */
export const isEmpty = (value: unknown) => {
  return value == null && typeof value == "undefined";
};

/**
 * @description 树转数组，队列实现广度优先遍历
 * @param {Array} data  数据
 * @param {Object} props `{ children: 'children' }`
 */

export const treeToArray = (data: any[], props = { children: "children" }) => {
  data = cloneDeep(data);
  const { children } = props;
  const newData = [];
  const queue: any[] = [];
  data.forEach((child: any) => queue.push(child));
  while (queue.length) {
    const item: any = queue.shift();
    if (item[children]) {
      item[children].forEach((child: any) => queue.push(child));
      delete item[children];
    }
    newData.push(item);
  }
  return newData;
};

/**
 * @description 数组转
 * @param {Array} data  数据
 * @param {Object} props `{ parent: 'pid', children: 'children' }`
 */

export const arrayToTree = (
  data: any[],
  props = { id: "id", parentId: "pid", children: "children" }
) => {
  data = cloneDeep(data);
  const { id, parentId, children } = props;
  const result: any[] = [];
  const map = new Map();
  data.forEach((item) => {
    map.set(item[id], item);
    const parent = map.get(item[parentId]);
    if (parent) {
      parent[children] = parent[children] ?? [];
      parent[children].push(item);
    } else {
      result.push(item);
    }
  });
  return result;
};

/**
 * @description 获取正确的路经
 * @param {String} path  数据
 */
export function getNormalPath(path: string) {
  if (path.length === 0 || !path || path == "undefined") {
    return path;
  }
  const newPath = path.replace("//", "/");
  const length = newPath.length;
  if (newPath[length - 1] === "/") {
    return newPath.slice(0, length - 1);
  }
  return newPath;
}

/**
 * @description对象格式化为Query语法
 * @param { Object } params
 * @return {string} Query语法
 */
export function objectToQuery(params: Record<string, any>): string {
  let query = "";
  for (const props of Object.keys(params)) {
    const value = params[props];
    const part = encodeURIComponent(props) + "=";
    if (!isEmpty(value)) {
      if (isObject(value)) {
        for (const key of Object.keys(value)) {
          if (!isEmpty(value[key])) {
            const params = props + "[" + key + "]";
            const subPart = encodeURIComponent(params) + "=";
            query += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        query += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return query.slice(0, -1);
}

/**
 * @description 时间格式化
 * @param dateTime { number } 时间戳
 * @param fmt { string } 时间格式
 * @return { string }
 */
// yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合
export const timeFormat = (dateTime: number, fmt = "yyyy-mm-dd") => {
  // 如果为null,则格式化当前时间
  if (!dateTime) {
    dateTime = Number(new Date());
  }
  // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
  if (dateTime.toString().length == 10) {
    dateTime *= 1000;
  }
  const date = new Date(dateTime);
  let ret;
  const opt: any = {
    "y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "h+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "s+": date.getSeconds().toString(), // 秒
  };
  for (const k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
};

/**
 * @description 获取不重复的id
 * @param length { Number } id的长度
 * @return { String } id
 */
export const getNonDuplicateID = (length = 8) => {
  let idStr = Date.now().toString(36);
  idStr += Math.random().toString(36).substring(3, length);
  return idStr;
};

/**
 * 腾讯手机号验证
 * @param {string} mobileNumber 手机号
 * @param {string} sms_scene 验证码类型
 */
export function captcha() {
  return new Promise((res, rej) => {
    var captcha1 = new TencentCaptcha(
      config.tencentCode,
      (response: anyObj) => {
        if (response.ticket) {
          let data = {
            randstr: response.randstr,
            ticket: response.ticket,
          };
          res(data);
        } else {
          rej();
        }
      }
    );
    captcha1.show();
  });
}

/**
 * 图片校验 感觉很别扭但暂时不想优化了。
 * @param {*} file 文件流
 * @param {*} options 校验配置
 * @param options.size { string } 文件大小 2K， 2M， 2G默认K
 * @param options.height {number} 图片高度 100
 * @param options.width { number} 图片宽度 100
 * @param options.rule {string} equal 是否相等 less 判断不能小于 默认判断不能小于
 *
 */
export function imgValidator(file: File, options: anyObj = {}) {
  if (!file) {
    throw new Error("请传入图片文件");
  }
  if (Object.prototype.toString.call(file) != "[object File]") {
    throw new Error("参数1请传入图片文件");
  }
  if (!options || !options.rule) {
    options.rule = options.rule || "other";
  }
  const keyToLabel: anyObj = {
    width: "宽度",
    height: "高度",
    size: "大小",
    equal: "需要为",
    less: "不能小于",
    other: "不能大于",
  };
  let flag = true;
  let tipLabel = "",
    validatorVal: number | string = "";
  let fileSize: number;
  if (options.size) {
    const sizeUnit =
      options.size.indexOf("M") > -1
        ? "M"
        : options.size.indexOf("G") > -1
        ? "G"
        : "K";
    if (sizeUnit == "M") {
      fileSize = file.size / 1024 / 1024;
    } else if (sizeUnit == "K") {
      fileSize = file.size / 1024;
    } else if (sizeUnit == "G") {
      fileSize = file.size / 1024 / 1024 / 1024;
    }
  }
  return new Promise(async (res, rej) => {
    for (const key in options) {
      if (Object.hasOwnProperty.call(options, key)) {
        const val = parseFloat(options[key]);
        let img = await readFileToImg(file);
        if (key == "rule") continue;
        // 校验大小
        if (key == "size") {
          flag = !(fileSize > val);
        } else {
          //校验其他属性
          // 校验是否判断相等还是大小

          if (options.rule == "equal") {
            flag = !(img[key as keyof typeof img] != val);
          } else if (options.rule == "less") {
            // 判断不能小于
            flag = !((img[key as keyof typeof img] as number) < val);
          } else {
            //默认判断是否大于
            flag = !((img[key as keyof typeof img] as number) > val);
          }
        }
        if (!flag) {
          tipLabel = keyToLabel[key];
          validatorVal = val;
          break;
        }
      }
    }
    // 校验失败
    if (!flag) {
      let tipStr = `图片${tipLabel}${
        tipLabel == "大小" ? keyToLabel.other : keyToLabel[options.rule]
      }${tipLabel == "大小" ? options.size : validatorVal}${
        tipLabel == "大小" ? "" : "px"
      }`;
      ElMessageBox.alert(tipStr, "提示", {
        type: "warning",
      });
      rej();
    }
    // 校验通过
    if (flag) {
      res(true);
    }
  });
}

/**
 * 文件流转图片
 * @param {Blob} file
 * @returns {Promise<HTMLImageElement>} 图片节点
 */
export function readFileToImg(file: File): Promise<HTMLImageElement> {
  if (!file) {
    throw new Error("请传入图片文件");
  }
  if (Object.prototype.toString.call(file) != "[object File]") {
    throw new Error("参数1请传入图片文件");
  }
  return new Promise((res, rej) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.src = e.target?.result as string;
      img.onload = () => {
        res(img);
      };
    };
    fileReader.onerror = rej; // 当读取文件失败时，reject promise
  });
}
